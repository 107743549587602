<template>
  <div class="home" style="width: 100%">
    <div style="height: 200px"></div>
    <!-- <el-image style="width: 100%;margin:30px 0" :src="img1" fit="cover"></el-image> -->
  </div>
</template>

<script>
import img1 from '@/assets/images/banner1.jpg'
export default {
  data () {
    return {
      img1
    }
  }
}
</script>
