<template>
  <div id="app">
    <div class="header">
      <div class="header-top">
        <div class="header-top-in">
          <!-- <span class="item">您好！欢迎来到缺钱么！</span>
          <span class="item">|</span>
          <span class="item">关注 v</span>
          <span class="item">|</span>
          <span class="item">手机APP v</span>
          <span class="item">客服邮箱：kefu@queqianme.com</span>
          <span class="item">全国服务热线：400-9305558</span> -->
        </div>
      </div>
      <div class="header-bottom">
        <div class="header-bottom-in">
          <img src="@/assets/images/logo.png" alt="" />
          <!-- <div class="tab">
            <span :class="['item', {active: activeName==='/'}]" @click="taggleActive('/')">首页</span>
            <span :class="['item', {active: activeName==='/about'}]" @click="taggleActive('/about')">关于我们</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="carouse">
      <el-carousel height="360px">
        <el-carousel-item v-for="item in imgs" :key="item.src">
          <el-image style="width: 100%; height: 100%" :src="item.src" fit="cover" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="qrCode" style="height: 95%;">
        <div class="description">
          <h5>三分钟找到优质产品</h5>
          <h5>多样化你的优质选择</h5>
          <h5>年轻人随身优质超市</h5>
        </div>
        <div class="code"><img src="@/assets/images/1618305357.png" /></div>
        <div class="download"><span>免费下载</span><img src="@/assets/images/xiazia.png" alt="下载最新版本" /></div>
      </div> -->
    </div>
    <div class="appMain">
      <router-view />
    </div>
    <!-- <div class="floatRight">
      <div class="img">
        <span class="con">
          <img src="@/assets/images/1618305357.png" alt="">
          <span class="txt">扫描下载APP</span>
        </span>
      </div>
      <div class="img">
        <span class="con">
          <img src="@/assets/images/weixin.png" alt="">
          <span class="txt">微信扫一扫</span>
          <span class="txt">在线咨询</span>
        </span>
      </div>
      <div class="img">
        <span class="con txtCon">
          <span class="txt">客服热线</span>
          <span class="txt">400-9305558</span>
        </span>
      </div>
    </div> -->
    <footer style="position:fixed;bottom:0">
      <div class="container">
        <div class="footTitle">
          <!-- <span class="fontBold">友情链接</span> -->
          <!-- <span>贷款</span> -->
        </div>
        <div class="footMain">
          <div class="row1">
            <!-- <span>首页</span> -->
            <!-- <span>全国服务</span> -->
            <!-- <span>安全保障</span> -->
            <!-- <span>贷款专题</span> -->
            <!-- <span>网站声明</span> -->
            <!-- <span>网站地图</span> -->
            <!-- <span>TAG标签</span> -->
            <!-- <span>常见问题</span> -->
          </div>
          <div class="row2">
            <!-- <span>客服热线：400-9305558</span> -->
            <!-- <span>媒体合作media@queqianme.com</span> -->
            <!-- <span>总部地址：广州市荔湾区康王中路486号1902房</span> -->
          </div>
          <div class="row3">
            <!-- <span>Copyright©2015 广东缺钱么网络科技有限公司</span> -->
            <span><a href="https://beian.miit.gov.cn">备案号：粤ICP备15054436号</a></span>
          </div>
          <div class="row4">
            <!-- <img src="@/assets/images/footerTrustutn.png" alt="" /> -->
            <!-- <img src="@/assets/images/u185.png" alt="" /> -->
            <!-- <img src="@/assets/images/cert.png" alt="" /> -->
            <!-- <img src="@/assets/images/hy.png" alt="" /> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import img1 from '@/assets/images/banner1.jpg'
import img2 from '@/assets/images/banner-gonggao2-pc.png'
// import img3 from '@/assets/images/banner-1.jpg'
// import img4 from '@/assets/images/banner-disclaimer.png'
export default {
  data () {
    return {
      activeName: '',
      imgs: [
        {
          src: img2,
          link: 'https://mp.weixin.qq.com/s/hPaEYMQ_JDm32DVZBSYNFA'
        }
        // {
        //   src: img3,
        //   link: ''
        // },
        // {
        //   src: img4,
        //   link: 'https://queqianmewww.oss-cn-beijing.aliyuncs.com/%E7%BC%BA%E9%92%B1%E4%B9%88%E5%A3%B0%E6%98%8E.jpg'
        // },
        // {
        //   src: img1,
        //   link: 'http://activity.queqianme.com/2017-06-02/i2017-06-02.html'
        // }
      ]
    }
  },
  watch: {
    $route: {
      handler (newVal, oldVal) {
        this.activeName = newVal.path
      },
      immediate: true
    }
  },
  methods: {
    golink (link) {
      if (!link) {
        return false
      }
      window.open(link)
    },
    taggleActive (name) {
      if (name === this.activeName) {
        return false
      }
      this.activeName = name
      this.$router.push(name)
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  height: 100%;
}
#app {
  position: relative;
  height: 100%;
  font-size: 14px;
  color: #585858;
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #fff;
  text-decoration: none;
}

.header {
  height: 100px;
  .header-top {
    height: 35px;
    line-height: 35px;
    background: #dbdbdb;
  }
  .header-top-in {
    width: 1170px;
    margin: 0 auto;
    text-align: right;
  }
  .header-top-in .item {
    margin-left: 10px;
  }
  .header-bottom-in {
    width: 1170px;
    margin: 0 auto;
    overflow: hidden;
  }
  .header-bottom-in img {
    float: left;
    width: 135px;
    height: 58px;
    margin-top: 7px;
  }
  .header-bottom-in .tab {
    height: 65px;
    margin-left: 200px;
    text-align: right;
    padding-top: 30px;
    box-sizing: border-box;
    .item {
      display: inline-block;
      width: 97px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      font-size: 18px;
      cursor: pointer;
    }
    .item:hover {
      color: #fff;
      background: #f47920;
    }
  }
  .header-bottom-in .tab .active {
    color: #fff;
    background: #f47920;
  }
}

.carouse {
  position: relative;
  height: 360px;
  overflow: hidden;
  margin-top: 5px;
  .qrCode {
    position: absolute;
    width: 250px;
    height: 95%;
    right: 19%;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    background: rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    z-index: 10;
    .description {
      padding: 0 15px;
      height: 100px;
      h5 {
        color: #fff;
        margin: 8px 0;
        font-size: 18px;
        font-weight: normal;
      }
    }
    .code {
      padding: 0 15px;
      img {
        width: 144px;
        height: 144px;
        margin: 5px 10px;
        vertical-align: bottom;
      }
    }
    .download {
      width: 180px;
      height: 37px;
      background: #fff;
      color: #f47920;
      font-size: 18px;
      text-align: center;
      line-height: 37px;
      margin: 5px auto 0;
      border-radius: 5px;
      box-sizing: border-box;
      span {
        margin-right: 10px;
      }
      img {
        width: 26px;
        height: 24px;
        vertical-align: middle;
      }
    }
  }
}

// .appMain {
//   width: 1170px;
//   margin: 55px auto;
// }

.floatRight {
  position: fixed;
  right: 30px;
  bottom: 100px;
  width: 50px;
  z-index: 10;
  .img {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    .con {
      position: absolute;
      top: 0;
      left: -153px;
      width: 148px;
      padding: 5px;
      background: #fff;
      border: 1px solid #e8e8e8;
      box-sizing: border-box;
      display: none;
      img {
        height: 138px;
      }
      .txt {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    .txtCon {
      background: #e8721d;
      font-size: 12px;
      color: #fff;
    }
  }
  .img:nth-child(1) {
    background: url('./assets/images/slide-1-1.png') no-repeat;
  }
  .img:nth-child(1):hover {
    background: url('./assets/images/slide-1-2.png') no-repeat;
    .con {
      display: block;
    }
  }
  .img:nth-child(2) {
    background: url('./assets/images/slide-2-1.png') no-repeat;
  }
  .img:nth-child(2):hover {
    background: url('./assets/images/slide-2-2.png') no-repeat;
    .con {
      display: block;
    }
  }
  .img:nth-child(3) {
    background: url('./assets/images/slide-3-1.png') no-repeat;
  }
  .img:nth-child(3):hover {
    background: url('./assets/images/slide-3-2.png') no-repeat;
    .con {
      display: block;
    }
  }
  .img:nth-child(4) {
    background: url('./assets/images/slide-4-1.png') no-repeat;
  }
  .img:nth-child(4):hover {
    background: url('./assets/images/slide-4-2.png') no-repeat;
    .con {
      display: block;
    }
  }
}

footer {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  background: #242424;
  color: #fff;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 20px 0;
  .container {
    width: 1170px;
    margin: 0 auto;
    text-align: center;
  }
  .footTitle {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e0e0e0;
    span {
      display: inline-block;
      width: 270px;
      text-align: center;
    }
    .fontBold {
      font-weight: bold;
    }
  }
  .footMain {
    padding: 15px 0;
    .row1 {
      margin-bottom: 15px;
      span {
        display: inline-block;
        width: 120px;
        height: 20px;
        line-height: 20px;
        border-right: 1px solid #fff;
      }
      span:last-child {
        border-right: 0 solid #fff;
      }
    }
    .row2,
    .row3 {
      margin-bottom: 15px;
      span {
        padding: 0 80px;
        border-right: 1px solid #fff;
      }
      span:last-child {
        border-right: 0 solid #fff;
      }
    }
    .row4 {
      padding-top: 15px;
      img {
        width: 127px;
        margin: 0 55px;
      }
    }
  }
}
</style>
